import Fotos from "@components/pagePostulacion/image/fotogrupo.png"

//img ADN
import agil from "@components/pagePostulacion/image/agil.svg"
import remoto from "@components/pagePostulacion/image/remoto.svg"
import chat from "@components/pagePostulacion/image/chat.svg"
import empatia from "@components/pagePostulacion/image/empatia.svg"
import ideas from "@components/pagePostulacion/image/ideas.svg"
import geek from "@components/pagePostulacion/image/geek.svg"

const data = {
  intro: {
    title: "Innovación y desarrollo pensando en nuestros clientes",
    textbody:
      "¿Te imaginas trabajar en lo que más te gusta en un lugar super cool? Aquí encontrarás todo lo que siempre has buscado. Si eres buena onda, aquí es tu lugar.",
    image: Fotos,
  },
  cards: [
    {
      img: agil,
      title: "Somos ágiles",
      text:
        "El ADN de nuestro trabajo está en ser ágiles. Si estás acostumbrado a los métodos scrum y de optimización de trabajos, entonces aquí la vas a romper.",
    },
    {
      img: remoto,
      title: "Trabajas donde quieres ",
      text:
        "Ir a la oficina todos los días es cosa del pasado. Puedes trabajar de manera remota desde donde te encuentres sin ningún problema.",
    },
    {
      img: chat,
      title: "Nos entendemos ",
      text:
        "Cada uno de nosotros tiene habilidades diferentes, pero no nos impide a enseñarnos entre nosotros sobre lo que hacemos. Así nos entendemos mejor. ",
    },
    {
      img: empatia,
      title: "Respeto y empatía",
      text:
        "Somos personas distintas, pero eso no nos exime a que nos llevemos bien entre todos. Si eres parte de Beex ya eres un compañero o compañera más ",
    },
    {
      img: ideas,
      title: "Valoramos tus ideas",
      text:
        "Desde el CEO hasta los practicantes, todos tenemos la misma voz de opinión para mejorar un proyecto. No nos gusta el esquema piramidal. ",
    },
    {
      img: geek,
      title: "Nos gusta lo geek",
      text:
        "Star Wars, Marvel, DC, videojuegos, y muchas otras cosas más. Si conoces de la cultura geek, nos vamos a entender bastante bien. ",
    },
  ],
  share: [
    {
      puesto: "Backend Python - Tiempo completo",
      area: "Desarrollo",
      time: "Full time",
      link: "/backend_python_tiempo_completo",
    },
    {
      puesto: "Ejecutivo Comercial - Tiempo Completo ",
      area: "Ejecutivo",
      time: "Full time",
      link: "/ejecutivo_comercial_tiempo_completo",
    },
    {
      puesto: "Frontend Vue.js 3 - Tiempo completo",
      area: "Desarrollo",
      time: "Full time",
      link: "/frontend_vue_tiempo_completo",
    },
    {
      puesto: "QA Analyst - Tiempo completo",
      area: "Desarrollo",
      time: "Full time",
      link: "/qa_analyst_tiempo_completo",
    },
  ],

  insert: {
    title: "¿No ves el puesto que haga match con tus habilidades?",
    text:
      "Déjanos tu CV para futuros proyectos. Cuando abramos nuevas convocatorias, tu CV será uno de los primeros que revisaremos.",
    areas: [
      "Comercial",
      "Marketing",
      "Growth",
      "Desarrollo",
      "Diseño, UX/ UI",
      "Customer Success",
    ],
  },
}

export default data
