import React from "react"
import img from "@components/pagePostulacion/image/fondonegro.png"

const ADN = ({ data }) => {
  return (
    <>
      <img src={img} alt="" className="fondo-adn" />
      <section className="section-adn">
        <p className="title-adn">Más que un trabajo</p>
        <div className="row-modules-postulacion">
          {data.map((elemento, i) => {
            return (
              <div
                key={i}
                className="module-agile"
              >
                <div className="modules-agile">
                  <div className="modules-pagescasos-img">
                    <img
                      loading="lazy"
                      src={elemento.img}
                      className="modules-icon"
                      alt="..."
                    />
                  </div>
                  <h3 className="title-agile">{elemento.title} </h3>
                  <p className="body-agile">{elemento.text}</p>
                </div>
              </div>
            )
          })}
        </div>
      </section>

      <img src={img} alt="" className="fondo-adn" />
    </>
  )
}

export default ADN
