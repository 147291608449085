import React from "react"
import { Link } from "gatsby"
import TitleUnderline from "./titleUnderline"

const Start = ({ data, location }) => {
  const buttonShareView = e => {
    e.preventDefault()
    const sectionShare = document.getElementById("puestos")
    sectionShare.scrollIntoView(true)
  }

  return (
    <>
      <section className="intro-agile">
        <section
          className="section-text-intro"
        >
          <div className="text-intro-postulacion">
            <div className="btn-return return-agile">
              <Link
                to={`/nosotros/${location.search}`}
                className="link-to-return" aria-label="enlace"
              >
                <span className="flecha-izquierda">
                  <svg
                    width="16"
                    height="10"
                    viewBox="0 0 16 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15 5L1 5M1 5L5.09091 9M1 5L5.09091 0.999999"
                      stroke="#2C5C82"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
                Regresar
              </Link>
            </div>
            <p className="text-title-start-postulacion">
              No has encontrado el trabajo de tus sueños. <TitleUnderline underline="AÚN." />
            </p>
          </div>
          <p className="text-body-start-postulacion">{data.textbody} </p>

          <div>
            <button
              className="btn-start-postulacion"
              id="button-share-view"
              onClick={e => buttonShareView(e)}
            >
              Quiero ver las ofertas de trabajo
            </button>
          </div>
        </section>
        <section className="img-intro-postulacion">
          <img
            loading="lazy"
            className="photo-agile"
            src={data.image}
            alt="fotos"
          />
        </section>
      </section>
    </>
  )
}

export default Start
