import React from "react"

const titleUnderline = ({ underline }) => (
  <span>
    {underline}
    <svg  style={{bottom: '5px'}} width="126" height="10" viewBox="0 0 126 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M23.4254 10C20.8204 9.07169 1.74598 10.1081 0.359542 9.82831C-0.772357 9.45317 1.03652 2.84689 2.08718 1.87407C2.45545 1.52436 2.8833 1.47351 3.86355 1.63247C5.41788 1.93131 25.5159 1.33997 25.5159 1.33997C25.7812 1.54344 26.5774 0.856744 26.7236 1.10472C27.1839 1.68332 59.7599 0.894891 62.5382 0.8631C64.2225 0.844025 62.8902 1.23824 64.8724 1.07928C69.0967 0.761364 78.3035 1.06657 81.5042 0.704142C84.0659 0.405302 84.6183 0.392594 87.0771 0.63421C89.59 0.850392 102.951 0.538832 104.478 0.074676C104.852 -0.0524901 105.139 -0.00797814 105.139 0.138263C105.139 0.170054 114.557 0.278142 114.6 0.392591C114.671 0.538832 114.958 0.608769 115.223 0.507036C115.716 0.354437 125.74 -0.198737 125.897 0.227269C126.39 1.44806 125.009 6.64915 123.845 7.91446C122.653 9.26878 116.111 7.74915 110.852 8.56302L86.3676 8.64568L81.1197 9.15434C80.5186 8.71561 78.206 9.82196 76.8088 9.03353C76.2184 8.72197 72.0483 8.67745 71.1276 9.00809C70.3261 9.26242 48.9933 8.62023 46.8053 9.50403C42.9059 8.76647 27.0919 9.82197 23.4254 10Z" fill="#FFA700"/>
</svg>

  </span>
)

export default titleUnderline
